@tailwind base;
@tailwind components;
@tailwind utilities;

.caprasimo-regular .mainText {
  font-family: "Caprasimo", serif;
}

.inter-tight .subText {
  font-family: "Inter Tight", sans-serif;
}

.montserrat .logo {
  font-family: "Montserrat", sans-serif;
}

.boxShadow {
  box-shadow: 2px 2px 10px #28F45A;
}

.hoverLinks:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 2px #28F45A;
}

.tipButton:hover {
  transform: scale(1.05);
}

.tipButton:active {
  transform: scale(1.05);
}